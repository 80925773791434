<template>
  <div>
    <p class="media-heading">
      <span class="font-weight-bolder">
        {{ notification.title }}
      </span>
    </p>
    <small class="notification-text">{{ notification.message }}</small>
  </div>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
}
</script>
