<template>
  <div
    v-if="showPromotions"
    class="popup"
  >
    <div
      class="d-flex justify-content-end cursor-pointer popup-close-icon mb-25"
      @click="dismissPromotions"
    >
      <feather-icon icon="XIcon" />
    </div>

    <b-carousel
      v-model="slide"
      :controls="promotions.length > 1"
      :indicators="promotions.length > 1"
      class="position-relative"
    >
      <b-carousel-slide
        v-for="promotion in promotions"
        :key="promotion.id"
      >
        <template v-slot:img>
          <img
            :src="promotion.img"
            :alt="promotion.title"
            class="img-fluid d-block img-height"
          />
        </template>
      </b-carousel-slide>
    </b-carousel>

    <div
      v-if="activePromotion"
      class="d-flex flex-column"
    >
      <p class="d-flex media-heading justify-content-between mt-25">
        <span class="font-weight-bolder">
          {{ activePromotion.title }}
        </span>

        <span>
          {{ activePromotion.startDate }} - {{ activePromotion.endDate }}
        </span>
      </p>

      <b-button
        variant="primary"
        @click="goToPromotion(activePromotion.id)"
      >
        {{ $t('Participate') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import axios from '@/libs/axios'
import {
  BButton, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCarousel,
    BCarouselSlide,
  },
  data() {
    return {
      promotions: [],
      showPromotions: false,
      slide: 0,
    }
  },
  computed: {
    activePromotion() {
      return this.promotions[this.slide]
    },
  },
  created() {
    const now = new Date().getTime()
    const hidePromotion = localStorage.getItem('hidePromotion')

    if (!hidePromotion || parseInt(hidePromotion, 10) < (now - 24 * 60 * 60 * 1000)) {
      this.showPromotions = true

      axios.get('promotions/search').then(res => {
        this.promotions = res.data.content

        if (this.promotions.length) {
          this.showPromotions = true
        }
      })
    }
  },
  methods: {
    goToPromotion(id) {
      this.$router.push({ name: 'promotion-single', params: { id } })

      this.showPromotions = false
    },

    dismissPromotions() {
      localStorage.setItem('hidePromotion', new Date().getTime())
      this.showPromotions = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/@core/scss/base/bootstrap-extended/_variables';
@import '@/@core/scss/base/components/_variables-dark';
.popup {
  position: fixed;
  left: 30px;
  bottom: 30px;
  width: 300px;
  padding: 10px 15px 15px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 1px 1px 12px -6px $primary;
  z-index: 10000;

}
.img-height {
  width: auto;
  max-height: 150px;
  margin: 0 auto;
}
.dark-layout .popup {
  background-color: $theme-dark-card-bg;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.24);
}
.popup-close-icon {
  line-height: 26px;
}
</style>
