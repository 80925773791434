import { getUserRights } from '@/auth/utils'
import menuLinks from '@/navigation/menuLinks'

const rights = getUserRights()

const pages = []
menuLinks.forEach(page => {
  if (page.route && (!page.authority || rights.includes(page.authority))) {
    pages.push(page)
  }
  if (page.children) {
    page.children.forEach(child => {
      if (child.route && (!child.pauthority || rights.includes(child.authority))) {
        pages.push(child)
      }
    })
  }
})

/* eslint-disable */
// prettier-ignore
export default {
  pages: {
    key: 'title',
    data: pages,
  },
}
/* eslint-enable */
