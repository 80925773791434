<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        :alt="currentLocale.locale"
        height="14px"
        width="22px"
      />
      <span class="d-none d-md-inline ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="changeRemoteLocale(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        :alt="localeObj.locale"
        height="14px"
        width="22px"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'

/* eslint-disable import/no-extraneous-dependencies */
import flatpickr from 'flatpickr'
import { Greek } from 'flatpickr/dist/l10n/gr'
import { english } from 'flatpickr/dist/l10n/default'

import { localize } from 'vee-validate'
/* eslint-enable import/no-extraneous-dependencies */

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    userData() {
      return this.$store.getters['app-common/userData']
    },

    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
  },
  mounted() {
    flatpickr.localize(this.$i18n.locale === 'el' ? Greek : english)

    localize(this.$i18n.locale)

    this.$watch('userData.languageCode', (n, o) => {
      if (n !== o) this.changeLocale(n)
    })
  },
  methods: {
    changeLocale(locale) {
      this.$i18n.locale = locale

      const getHTMLTag = document.documentElement
      getHTMLTag.setAttribute('lang', locale)

      flatpickr.localize(locale === 'el' ? Greek : english)

      localize(locale)

      this.$store.commit('app-common/userData', {
        ...this.userData,
        languageCode: locale,
      })

      localStorage.setItem('userData', JSON.stringify({
        ...this.userData,
        languageCode: locale,
      }))
    },

    changeRemoteLocale(locale) {
      this.changeLocale(locale)

      this.$store.dispatch('app-common/updateUserLanguage', {
        languageCode: locale,
      })
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'el',
        img: require('@/assets/images/flags/el.svg'),
        name: 'Ελληνικά',
      },
      {
        locale: 'en',
        img: require('@/assets/images/flags/us.svg'),
        name: 'English',
      },
    ]
    /* eslint-disable global-require */

    return {
      locales,
    }
  },
}
</script>
