export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'HomeIcon',
    isBookmarked: true,
  },
  {
    title: 'My Products',
    icon: 'PackageIcon',
    route: '',
    authority: 'Products_Product_View',
    children: [
      {
        title: 'Products',
        icon: 'PackageIcon',
        route: 'products-list',
        authority: 'Products_Product_View',
        isBookmarked: true,
      },
      {
        title: 'Log',
        icon: 'ListIcon',
        route: 'products-log',
        authority: 'General_MainParameter_View',
        role: 'admin',
      },
    ]
  },
  {
    title: 'Active Partnerships',
    icon: 'ShoppingCartIcon',
    route: '',
    authority: '',
    role: 'admin',
    children: [
      {
        title: 'Supplier Products',
        icon: 'PackageIcon',
        route: 'supplier-products-list',
        authority: 'Users_Partner_Connect_Edit',
        role: 'seller',
      },
      {
        title: 'My Suppliers',
        icon: 'PackageIcon',
        route: 'seller-products-list',
        authority: 'Users_Partner_Connect_Edit',
        role: 'seller'
      },
      // {
      //   title: 'Sell Requests',
      //   icon: 'ShoppingBagIcon',
      //   route: 'sell-requests-list',
      //   authority: 'Products_Sell_Request_Edit',
      //   role: 'supplier',
      // },
    ],
  },
  { //menu item only visible for supplier            
    title: 'Active Partnerships',
    icon: 'ShoppingCartIcon',
    route: 'active-partners-list',
    authority: '',
  },  
  {
    title: 'Partnership Opportunities',
    icon: 'TargetIcon',
    route: '',
    authority: 'Users_Partner_Connect_Edit',
    role: 'supplier',
    children: [
      {
        title: 'Companies',
        icon: 'PackageIcon',
        route: 'active-sellers',
        authority: 'Users_Partner_Connect_Edit',
        role: 'supplier',
      },
    ],
  },
  {
    title: 'Partnership Opportunities',
    icon: 'TargetIcon',
    route: '',
    authority: 'Users_Partner_Connect_Edit',
    role: 'seller',
    children: [
      {
        title: 'Products',
        icon: 'PackageIcon',
        route: 'all-products-list',
        authority: 'Users_Partner_Connect_Edit',
        role: 'seller',
      },
      {
        title: 'Companies',
        icon: 'PackageIcon',
        route: 'active-suppliers',
        authority: 'Users_Partner_Connect_Edit',
        role: 'seller',
      },
    ],
  },
  {                                 //Orders and Customers not visible to supplier
    title: 'Orders',                
    icon: 'ShoppingBagIcon',
    route: 'orders-list',
    authority: 'Orders_Order_View',
    isBookmarked: true,
    role : 'seller',
  },
  {
    title: 'Customers',
    icon: 'UsersIcon',
    route: 'customers-list',
    authority: 'Users_Customer_View',
    role: 'seller',
  },
  {
    title: 'Leads',
    icon: 'TrendingUpIcon',
    route: '',
    authority: '',
    children: [
      {
        title: 'Leads',
        icon: 'TrendingUpIcon',
        route: 'leads-list',
        authority: 'General_Lead_View',
      },
      {
        title: 'Lead Categories',
        icon: 'ListIcon',
        route: 'leads-categories-list',
        authority: 'General_Lead_View',
      },
    ],
  },
  {
    title: 'Partner Requests',
    icon: 'PhoneIncomingIcon',
    route: 'my-partner-requests-list',
    authority: 'Users_Partner_Connect_Edit',
  },
  {
    title: 'Social Trade',
    route: 'social-trade',
    icon: 'MessageSquareIcon',
    authority: 'Chats_Chat_View',
    isBookmarked: true,
  },
  {
    title: 'Announcements',
    icon: 'BellIcon',
    route: 'announcements',
    authority: 'General_Announcement_View',
  },
  {
    title: 'Administration',
    route: '',
    icon: 'ShieldIcon',
    authority: '',
    children: [
      {
        title: 'Main Parameters',
        icon: 'SettingsIcon',
        route: 'main-parameters-list',
        authority: 'General_MainParameter_View',
      },
      {
        title: 'Companies',
        icon: 'BriefcaseIcon',
        route: 'companies-list',
        authority: 'Users_Company_View',
      },
      {
        title: 'Users',
        icon: 'UserIcon',
        route: 'users-list',
        authority: 'Users_User_View',
      },
      {
        title: 'States',
        icon: 'MapPinIcon',
        route: 'cover-areas-list',
        authority: 'General_Cover_Area_View',
      },
      {
        title: 'Municipalities',
        icon: 'MapPinIcon',
        route: 'municipalities-list',
        authority: 'General_Cover_Area_View',
      },
      {
        title: 'Service Coverage',
        icon: 'MapIcon',
        route: 'service-coverage',
        authority: 'General_Cover_Area_View',
      },
      {
        title: 'Badges',
        icon: 'AwardIcon',
        route: 'badges-list',
        authority: 'Users_Badges_View',
      },
      {
        title: 'Announcements',
        icon: 'BellIcon',
        route: 'announcements-list',
        authority: 'General_Announcement_View',
      },
      {
        title: 'Promotions',
        icon: 'CastIcon',
        route: 'promotions-list',
        authority: 'General_Promotion_View',
      },
      {
        title: 'Post Categories',
        icon: 'ListIcon',
        route: 'post-categories-list',
        authority: 'General_Post_Category_View',
      },
      {
        title: 'Product Categories',
        icon: 'ListIcon',
        route: 'product-categories-list',
        authority: 'General_Category_View',
        role: 'admin',
      },
      {
        title: 'Product Tags',
        icon: 'ListIcon',
        route: 'product-tags-list',
        authority: 'General_MainParameter_View',
        role: 'admin',
      },
      {
        title: 'Partner Requests',
        icon: 'PhoneIncomingIcon',
        route: 'partner-requests-list',
        authority: 'Users_Partner_Request_View',
      },
      {
        title: 'User Groups',
        icon: 'UsersIcon',
        route: 'user-groups-list',
        authority: 'Users_User_Group_View',
      },
      {
        title: 'Deal Subscriptions',
        icon: 'SlidersIcon',
        route: 'deal-subscriptions-list',
        authority: 'Users_User_Group_View',
      },
      {
        title: 'User Requests',
        icon: 'FileTextIcon',
        route: 'user-requests-list',
        authority: 'Users_User_Request_View',
      },
      // {
      //   title: 'Newsletter Wizard',
      //   icon: 'MailIcon',
      //   route: 'send-newsletter',
      //   // @TODO newsletter permissions
      //   authority: 'Users_User_Request_Edit',
      // },
      // {
      //   title: 'Emails',
      //   icon: 'MailIcon',
      //   children: [
      //     {
      //       title: 'Welcome',
      //       route: 'email-welcome',
      //     },
      //     {
      //       title: 'Activation',
      //       route: 'email-activation',
      //     },
      //     {
      //       title: 'Reset Password',
      //       route: 'email-reset-password',
      //     },
      //     {
      //       title: 'Subscription End',
      //       route: 'subscription-end',
      //     },
      //     {
      //       title: 'Payment Success',
      //       route: 'payment-success',
      //     },
      //     {
      //       title: 'Product Approved',
      //       route: 'product-approved',
      //     },
      //     {
      //       title: 'Monthly Report',
      //       route: 'monthly-report',
      //     },
      //   ],
      // },
    ],
  },
  {
    title: 'Deals',
    icon: 'BellIcon',
    route: 'deals-list',
    authority: 'General_MainParameter_View',
    role: 'admin',
  }, 
  {
    title: 'Subscription Payments',
    icon: 'FileTextIcon',
    authority: '',
    children : [
      {
        title: 'Normal Payments',
        icon: 'FileTextIcon',
        route: 'normal-subscription-payments-list',
        authority: 'Users_Payment_View',
      },
      {
        title: 'Deal Payments',
        icon: 'FileTextIcon',
        route: 'deal-subscription-payments-list',
        authority: 'Users_Payment_View',
      },
    ],
  },
]
