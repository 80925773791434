import { getUserRights, isRoleType } from '@/auth/utils'
import menuLinks from '@/navigation/menuLinks'

const rights = getUserRights()
const userMenu = []
menuLinks.forEach(item => {
  if((item.title==="Partnership Opportunities" && isRoleType('admin') && item.role!=='supplier')&&(!item.authority || rights.includes(item.authority))){
    userMenu.push({
      title: item.title,
      icon: item.icon,
      route: 'all-products-list',
      authority: 'Users_Partner_Connect_Edit',
    })
  }
  else if ((!item.authority || rights.includes(item.authority))&&(item.title==="My Products" && !isRoleType('admin'))){
    userMenu.push({
        title: item.title,
        icon: item.icon,
        route:'products-list',
        authority: 'Products_Product_View',
        isBookmarked: true,
      })
  }
  else if (item.children) {
    const mChildren = []
    item.children.forEach(child => {
      if(child.title==="My Suppliers" && isRoleType('admin')){
        child.title="Seller products"
      }
      if ((!child.authority || rights.includes(child.authority)) && (!child.role || isRoleType(child.role) || isRoleType(item.role))) {
        mChildren.push(child)
      }
    })
    if (mChildren.length) {
      userMenu.push({
        title: item.title,
        icon: item.icon,
        children: mChildren,
      })
    }
  } //condition to exclude the menu item only for supplier from other roles
  else if ((item.route === 'active-partners-list') && (!item.authority || rights.includes(item.authority)) && (isRoleType('admin') || isRoleType('seller'))) {
    userMenu.push()
  } 
  else if ((!item.authority || rights.includes(item.authority)) && (!item.role || isRoleType(item.role) || isRoleType('admin'))) {
    userMenu.push(item)
  }
})
export default [...userMenu]
