<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ fullName }}
        </p>
        <span class="user-status">{{ userData.company.title }} ({{ userData.activeUserGroup ? userData.activeUserGroup.title : userData.userGroup.title }})</span>
      </div>
      <b-avatar
        :src="userData.participant.profilePhoto"
        size="40"
        variant="light-primary"
        class="badge-minimal"
      >
        <feather-icon
          v-if="!fullName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="{ name: 'dashboard' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="HomeIcon"
        class="mr-50"
      />
      <span>{{ $t('Dashboard') }}</span>
    </b-dropdown-item>

    <b-dropdown-item
      :to="{ name: 'social-trade' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>{{ $t('Social Trade') }}</span>
    </b-dropdown-item>

    <b-dropdown-item
      v-if="hasSubscriptionRights"
      :to="{ name: 'invoice-list' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="FileTextIcon"
        class="mr-50"
      />
      <span>{{ $t('Payments') }}</span>
    </b-dropdown-item>

    <b-dropdown-item
      :to="{ name: 'account-settings' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>{{ $t('Settings') }}</span>
    </b-dropdown-item>

    <b-dropdown-item
      v-if="hasSubscriptionRights && isAdmin"
      :to="{ name: 'subscriptions' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SlidersIcon"
        class="mr-50"
      />
      <span>{{ $t('Subscriptions') }}</span>
    </b-dropdown-item>

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>{{ $t('Logout') }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import { getUserRights, isAdmin } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      avatarText,
    }
  },
  computed: {
    userData() {
      return this.$store.getters['app-common/userData']
    },

    fullName() {
      return `${this.userData.participant.firstName} ${this.userData.participant.lastName}`
    },
    hasSubscriptionRights() {
      return getUserRights().includes('Users_Subscriptions_View')
    },
    isAdmin() {
      return isAdmin()
    },
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.$store.commit('app-common/userData', userData)
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Redirect to login page
      this.$router.push({ name: 'login' })
    },
  },
}
</script>
